<template>
  <div class="hold-transition">
    <div class="container">
      <div class="wrapper">
        <section class="content-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header ">
                  <div class="row">
                    <div class="col-md-3">
                      <img
                        src="/img/logo-sos.png"
                        alt="Logo SOS CONTINGENCIAS"
                        style="width: 100px"
                      />
                    </div>
                    <div class="col-md-6">
                      <h2 class="text-center">
                        POLÍTICA DE TRATAMIENTO DE PROTECCIÓN DE DATOS
                      </h2>
                    </div>
                  </div>
                </div>
                <div class="card-body text-justify">
                  <h4>OBJETIVO</h4>
                  <p>
                    Garantizar los derechos de privacidad, intimidad, buen
                    nombre y autonomía en el tratamiento de datos personales,
                    determinando mecanismos de recolección, uso, transmisión y
                    almacenamiento de información personal relacionada con la
                    actividad de la organización.
                  </p>
                  <h4>ALCANCE</h4>
                  <p>
                    La presente política será aplicable a los datos personales
                    registrados en cualquier base de datos de
                    <b>S.O.S CONTINGENCIAS S.A.S</b> cuyo titular sea una
                    persona natural.<br /><br />

                    Todas sus actuaciones se regirán por los principios de
                    legalidad, finalidad, libertad, veracidad o calidad,
                    transparencia, acceso y circulación restringida, seguridad y
                    confidencialidad.<br /><br />

                    <b>S.O.S CONTINGENCIAS S.A.S</b>, que en adelante se
                    denominará <b>S.O.S CONTINGENCIAS S.A.S</b>, es una
                    organización especializada en Gestión del riesgo operativo,
                    prestación de servicios integrales para atención de
                    emergenciasy soporte logístico operativo asociado al
                    transporte.
                  </p>
                  <h4>DOMICILIO Y DIRECCIÓN:</h4>
                  <p>
                    <b>S.O.S CONTINGENCIAS S.A.S</b>, tiene su domicilio en la
                    ciudad de Bogotá DC., y su sede principal se encuentra
                    ubicada en la Av. Calle 24 No. 95A-80 Ed. Bussines Center El
                    Dorado.
                  </p>
                  <p>
                    Bogotá - Colombia.<br />
                    Página Web: www.soscontingencias.com.co<br />
                    Teléfono: PBX :(60-1) 744 2839
                  </p>
                  <h4>MARCO LEGAL</h4>
                  <ul>
                    <li>Constitución Política artículo 15.</li>
                    <li>Ley 1266 de 2008</li>
                    <li>Ley 1581 de 2012</li>
                    <li>
                      Decretos Reglamentarios 1727 de 2009 y 2952 de 2010 y
                      Decreto Reglamentario Parcial No. 1377de 2013.
                    </li>
                    <li>
                      Sentencias de la Corte Constitucional C-1011 de 2008 Y
                      C-748 de 2011
                    </li>
                  </ul>
                  <h4>DEFINICIONES</h4>
                  <ul>
                    <li>
                      <b>Autorización</b>: Consentimiento previo, expreso e
                      informado del Titular para llevar a cabo el Tratamiento de
                      datos personales.
                    </li>
                    <li>
                      <b>Aviso de privacidad</b>: Comunicación verbal o escrita
                      generada por el responsable, dirigida al Titular para el
                      tratamiento de sus datos personales, mediante la cual se
                      le informa acerca de la existencia de las políticas de
                      tratamiento de información que le serán aplicables, la
                      forma de acceder a las mismas y las finalidades del
                      tratamiento que se pretende dar a los datos personales.
                    </li>
                    <li>
                      <b>Base de Datos</b>: Conjunto organizado de datos
                      personales que sea objeto de tratamiento.
                    </li>
                    <li>
                      <b>Causahabiente</b>: Persona que ha sucedido a otra por
                      causa del fallecimiento de ésta (heredero).
                    </li>
                    <li>
                      <b>Dato Personal</b>: Cualquier información vinculada o
                      que pueda asociarse a una o varias personas
                      naturalesdeterminadas o determinables.
                    </li>

                    <li>
                      <b>Dato Público</b>: Es el dato que no sea semiprivado,
                      privado o sensible. Son considerados datos públicos, entre
                      otros, los datos relativos al estado civil de las
                      personas, a su profesión u oficio ya su calidad de
                      comerciante ode servidor público. Por su naturaleza, los
                      datos públicos pueden estar contenidos, entre otros, en
                      registros públicos, documentos públicos, gacetas y
                      boletines oficiales y sentencias judiciales debidamente
                      ejecutoriadas que no estén sometidas a reserva.
                    </li>

                    <li>
                      <b>Datos Sensibles</b>: Se entiende por datos sensibles
                      aquellos que afectan la intimidad del Titular o cuyo
                      usoindebido puede generar su discriminación, tales como
                      que revelen el origen racial o étnico, la orientación
                      política, las convicciones religiosas o filosóficas, la
                      pertenencia a sindicatos, organizaciones sociales, de
                      derechos humanos o que promueva intereses de cualquier
                      partido político o que garanticen los derechos ygarantías
                      de partidos políticos de oposición, así como los datos
                      relativos a la salud, a la vida sexual, y los datos
                      biométricos.
                    </li>
                    <li>
                      <b>Encargado del Tratamiento</b>: Persona natural o
                      jurídica, pública o privada, que por sí misma o en asocio
                      con otros, realice el Tratamiento de datos personales por
                      cuenta del Responsable del Tratamiento.
                    </li>
                    <li>
                      <b>Responsable del Tratamiento</b>: Persona natural o
                      jurídica, pública o privada, que por sí misma o en asocio
                      con otros, decida sobre la base de datos y/o el
                      Tratamiento de los datos.
                    </li>
                    <li>
                      <b>Titular</b>: Persona natural cuyos datos personales
                      sean objeto de Tratamiento.
                    </li>
                    <li>
                      <b>Tratamiento</b>: Cualquier operación o conjunto de
                      operaciones sobre datos personales, tales como la
                      recolección, almacenamiento, uso, circulación o supresión.
                    </li>
                    <li>
                      <b>Transferencia</b>: la transferencia de datos tiene
                      lugar cuando el responsable y/o encargado del tratamiento
                      de datos personales, ubicado en Colombia, envía la
                      información o los datos personales a un receptor, que a
                      suvez es responsable del tratamiento y se encuentra dentro
                      o fuera del país.
                    </li>

                    <li>
                      <b>Transmisión</b>: tratamiento de datos personales que
                      implica la comunicación de estos dentro o fuera del
                      territorio de la República de Colombia cuando tenga por
                      objeto la realización de un tratamiento por el encargado
                      por cuenta del responsable.
                    </li>
                  </ul>
                  <h4>PRINICIPIOS</h4>
                  <p>
                    Para efectos de garantizar la protección de datos
                    personales, <b>S.O.S CONTINGENCIAS S.A.S</b>, aplicará de
                    manera armónica e integral los siguientes principios, a la
                    luz de los cuales se deberá realizar el tratamiento,
                    transferencia y transmisión de datos personales:
                  </p>
                  <ul>
                    <li>
                      <b
                        >Principio de legalidad en materia de Tratamiento de
                        datos</b
                      >: El tratamiento de datos es una actividad reglada, la
                      cual deberá estar sujeta a las disposiciones legales
                      vigentes y aplicables rigen el tema.
                    </li>
                    <li>
                      <b>Principio de finalidad</b>: la actividad del
                      tratamiento de datos personales que realice
                      <b>S.O.S CONTINGENCIAS S.A.S</b>. o a la cual tuviere
                      acceso, obedecerán a una finalidad legítima en consonancia
                      con la ConstituciónPolítica de Colombia, la cual deberá
                      ser informada al respectivo titular de los datos
                      personales.
                    </li>
                    <li>
                      <b>Principio de libertad</b>: el tratamiento de los datos
                      personales sólo puede realizarse con el
                      consentimiento,previo, expreso e informado del Titular.
                      Los datos personales no podrán ser obtenidos o divulgados
                      sin previa autorización, o en ausencia de mandato legal,
                      estatutario, o judicial que releve el consentimiento.
                    </li>
                    <li>
                      <b>Principio de veracidad o calidad</b>: la información
                      sujeta a Tratamiento de datos personales debe ser veraz,
                      completa, exacta, actualizada, comprobable y comprensible.
                      Se prohíbe el Tratamiento de datos parciales,incompletos,
                      fraccionados o que induzcan a error.
                    </li>
                    <li>
                      <b>Principio de transparencia</b>: En el tratamiento de
                      datos personales, <b>S.O.S CONTINGENCIAS S.A.S</b>.,
                      garantizará al Titular su derecho de obtener en cualquier
                      momento y sin restricciones, información acerca de la
                      existencia de cualquier tipo de información o dato
                      personal que sea de su interés o titularidad.
                    </li>
                    <li>
                      <b>Principio de acceso y circulación restringida</b>: El
                      tratamiento de datos personales se sujeta a los límites
                      que se derivan de la naturaleza de éstos, de las
                      disposiciones de la ley y la Constitución. En
                      consecuencia, el tratamiento sólo podrá hacerse por
                      personas autorizadas por el titular y/o por las personas
                      previstas en la ley. Los datos personales, salvo la
                      información pública, no podrán estar disponibles en
                      internet u otros medios de divulgación o comunicación
                      masiva, salvo que el acceso sea técnicamente controlable
                      para brindar un conocimiento restringido sólo a los
                      titulares o terceros autorizados conforme a la ley. Para
                      estos propósitos la obligación de
                      <b>S.O.S CONTINGENCIAS S.A.S</b>. Será de medio.
                    </li>
                    <li>
                      <b>Principio de seguridad</b>: la información sujeta a
                      tratamiento por <b>S.O.S CONTINGENCIAS S.A.S</b>, se
                      deberá manejar con las medidas técnicas, humanas y
                      administrativas que sean necesarias para otorgar seguridad
                      a los registros evitando su adulteración, pérdida,
                      consulta, uso o acceso no autorizado o fraudulento.
                    </li>
                    <li>
                      <b>Principio de confidencialidad</b>: Todas las personas
                      que en <b>S.O.S CONTINGENCIAS S.A.S</b>., administren,
                      manejen, actualicen o tengan acceso a informaciones de
                      cualquier tipo que se encuentre en Bases de Datos, están
                      obligadas a garantizar la reserva de la información, por
                      lo que se comprometen a conservar y mantener de manera
                      estrictamente confidencial y no revelar a terceros, toda
                      la información que llegaren a conocer en la ejecución y
                      ejercicio de sus funciones; salvo cuando se trate de
                      actividades autorizadas expresamente por la ley de
                      protección de datos. Esta obligación persiste y se
                      mantendrá inclusive después de finalizada su relación con
                      alguna de las labores que comprende el Tratamiento.
                    </li>
                  </ul>
                  <h4>
                    DERECHOS DEL TITULAR DE LA INFORMACIÓN
                  </h4>
                  <p>
                    De acuerdo con lo contemplado por la normatividad vigente
                    aplicable en materia de protección de datos, los siguientes
                    son los derechos de los titulares de los datos personales:
                  </p>
                  <ul>
                    <li>
                      Acceder,conocer, actualizar y rectificar sus datos
                      personales frentea <b>S.O.S CONTINGENCIAS S.A.S</b>, en su
                      condición de responsable del tratamiento. Este derecho se
                      podrá ejercer, entre otros, frente a datos parciales,
                      inexactos, incompletos, fraccionados, que induzcan a
                      error, o aquellos cuyo tratamiento esté expresamente
                      prohibido o no haya sido autorizado.
                    </li>
                    <li>
                      Solicitar prueba de la autorización otorgada a
                      <b>S.O.S CONTINGENCIAS S.A.S</b> para el tratamiento de
                      datos, mediante cualquier medio válido, salvo en los casos
                      en que no es necesaria la autorización.
                    </li>
                    <li>
                      Ser informado por <b>S.O.S CONTINGENCIAS S.A.S</b>, previa
                      solicitud, respecto del uso que les ha dado a sus datos
                      personales.
                    </li>
                    <li>
                      Presentar ante la Superintendencia de Industria y
                      Comercio, o la entidad que hiciere sus veces, quejas por
                      infracciones a lo dispuesto en la ley 1581 de 2012 y las
                      demás normas que la modifiquen, adicionen o complementen,
                      previo trámite de consulta o requerimiento ante
                      <b>S.O.S CONTINGENCIAS S.A.S</b>
                    </li>
                    <li>
                      Revocar la autorización y/o solicitar la supresión del
                      dato cuando en el Tratamiento no se respeten los
                      principios, derechos y garantías constitucionales y
                      legales.
                    </li>
                    <li>
                      Acceder en forma gratuita a sus datos personales que hayan
                      sido objeto de tratamiento, al menos una vez cada mes
                      calendario, y cada vez que existan modificaciones
                      sustanciales de la presente política que motiven nuevas
                      consultas. Estos derechos podrán ser ejercidos por:
                      <ul>
                        <li>
                          El titular, quien deberá acreditar su identidad en
                          forma suficiente por los distintos medios que le ponga
                          a disposición <b>S.O.S CONTINGENCIAS S.A.S</b>.
                        </li>
                        <li>
                          Los causahabientes del titular, quienes deberán
                          acreditar tal calidad.
                        </li>
                        <li>
                          El representante y/o apoderado del titular, previa
                          acreditación de la representación o apoderamiento.
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h4>
                    DEBERES DE S.O.S CONTINGENCIAS S.A.S. COMO RESPONSABLE Y
                    ENCARGADA DEL TRATAMIENTO DE LOS DATOS PERSONALES
                  </h4>
                  <p>
                    <b>S.O.S CONTINGENCIAS S.A.S</b>, reconoce la titularidad
                    que de los datos personales ostentan las personas y en
                    consecuencia ellas de manera exclusiva pueden decidir sobre
                    los mismos. Por lo tanto, <b>S.O.S CONTINGENCIAS S.A.S</b>.,
                    utilizará los datos personales para el cumplimiento de las
                    finalidades autorizadas expresamente por el titular o por
                    las normas vigentes. En el tratamiento y protección de datos
                    personales, <b>S.O.S CONTINGENCIAS S.A.S</b>, tendrá los
                    siguientes deberes, sin perjuicio de otros previstos en las
                    disposiciones que regulen o lleguena regular esta materia:
                  </p>
                  <ul>
                    <li>
                      Garantizar al titular, en todo tiempo, el pleno y efectivo
                      ejercicio del derecho de hábeas data.
                    </li>
                    <li>
                      Solicitar y conservar, copia de la respectiva autorización
                      otorgada por el titular para el tratamiento de datos
                      personales.
                    </li>
                    <li>
                      Informar debidamente al titular sobre la finalidad de la
                      recolección y los derechos que le asisten en virtud de la
                      autorización otorgada.
                    </li>
                    <li>
                      Conservar la información bajo las condiciones de seguridad
                      necesarias para impedir su adulteración, pérdida,
                      consulta, uso o acceso no autorizado o fraudulento.
                    </li>
                    <li>
                      Garantizar que la información sea veraz, completa, exacta,
                      actualizada, comprobable y comprensible.
                    </li>
                    <li>
                      Actualizar oportunamente la información, atendiendo de
                      esta forma todas las novedades respecto de los datos del
                      titular. Adicionalmente, se deberán implementar todas las
                      medidas necesarias para que la información se mantenga
                      actualizada.
                    </li>
                    <li>
                      Rectificar la información cuando sea incorrecta y
                      comunicar lo pertinente.
                    </li>
                    <li>
                      Respetar las condiciones de seguridad y privacidad de la
                      información del titular.
                    </li>
                    <li>
                      Tramitar las consultas y reclamos formulados en los
                      términos señalados por la ley.
                    </li>
                    <li>
                      Identificar cuando determinada información se encuentra en
                      discusión por parte del titular.
                    </li>
                    <li>
                      Informar a solicitud del titular sobre el uso dado a sus
                      datos.
                    </li>
                    <li>
                      Informar a la autoridad de protección de datos cuando se
                      presenten violaciones a los códigos de seguridad y existan
                      riesgos en la administración de la información de los
                      titulares.
                    </li>
                    <li>
                      Cumplir los requerimientos e instrucciones que imparta la
                      Superintendencia de Industria y Comercio sobre el tema en
                      particular.
                    </li>
                    <li>
                      Usar únicamente datos cuyo tratamiento esté previamente
                      autorizado de conformidad con lo previsto en la ley 1581
                      de 2012.
                    </li>
                    <li>
                      Velar por el uso adecuado de los datos personales de los
                      aspirantes y funcionarios activos, en aquellos casos en
                      que se entra autorizado el tratamiento de sus datos.
                    </li>
                    <li>
                      Registrar en la base de datos las leyenda "reclamo en
                      trámite" en la forma en que se regula en la ley.
                    </li>
                    <li>
                      Insertar en la base de datos la leyenda "información en
                      discusión judicial" una vez notificado por parte de la
                      autoridad competente sobre procesos judiciales
                      relacionados con la calidad del dato personal.
                    </li>
                    <li>
                      Abstenerse de circular información que esté siendo
                      controvertida por el titular y cuyo bloqueo haya sido
                      ordenado por la Superintendencia de Industria y Comercio.
                    </li>

                    s. Permitir el acceso a la información únicamente a las
                    personas que pueden tener acceso a ella. t. Usar los datos
                    personales del titular sólo para aquellas finalidades para
                    las que se encuentre facultada debidamente y respetando en
                    todo caso la normatividad vigente sobre protección de datos
                    personales.
                  </ul>
                  <h4>AUTORIZACIÓN Y CONSENTIMIENTO DEL TITULAR</h4>
                  <p>
                    <b>S.O.S CONTINGENCIAS S.A.S</b>, requiere del
                    consentimiento libre, previo, expreso e informado del
                    titular de los datos personales para el tratamiento de
                    estos, exceptos en los casos expresamente autorizados en
                    laley.<br /><br />

                    <b>S.O.S CONTINGENCIAS S.A.S</b>. implementará los
                    mecanismos necesarios para almacenar y disponer evidencias
                    de los nombrados consentimientos.
                  </p>
                  <h4>
                    TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS Y FINALIDAD DE
                    ESTE
                  </h4>
                  <p>
                    El tratamiento de los datos personales se realizará bajo lo
                    dispuesto en el marco legal que regula la materia y en
                    virtud de nuestra condición de organización especializada en
                    gestión del riesgo operativo y prestación de servicios
                    integrales para atención de emergencias y soporte logístico
                    operativo asociado al transporte.
                  </p>
                  <h4>AVISO DE PRIVACIDAD</h4>
                  <p>
                    El Aviso de Privacidad es el documento físico, electrónico o
                    en cualquier otro formato, puesto a disposición del titular
                    para informarle acerca del tratamiento de sus datos
                    personales. A través de este documento se comunica al
                    titular la información relacionada con la existencia de las
                    políticas de tratamiento de información de
                    <b>S.O.S CONTINGENCIAS S.A.S</b>, y que le serán aplicables,
                    la forma de acceder a las mismas y las características del
                    tratamiento que se pretende dar a los datos personales.
                  </p>
                  <h4>GARANTÍAS DEL DERECHO DE ACCESO</h4>
                  <p>
                    Para garantizar el derecho de acceso del titular de los
                    datos, <b>S.O.S CONTINGENCIAS S.A.S</b>., pondrá a
                    disposición de éste, previa acreditación de su identidad,
                    legitimidad, o personalidad de su representante, sin costo o
                    erogación alguna, de manera pormenorizada y detallada, los
                    respectivos datos personales a través de todo tipo de medio,
                    incluyendo los medios electrónicos que permitan el acceso
                    directo del titular a ellos.
                  </p>
                  <h4>
                    PROCEDIMIENTO PARA LA ATENCIÓN DE CONSULTAS, RECLAMOS,
                    PETICIONES DE RECTIFICACIÓN, ACTUALIZACIÓN Y SUPRESIÓN DE
                    DATOS
                  </h4>
                  <p>
                    Consultas: Las consultas podrán formularse al correo
                    <a>servicioalcliente@soscontingencias.com.co</a>. Las
                    consultas serán atendidas en un término máximo de diez (20)
                    días hábiles contados a partir de la fecha de su recibo.
                    Cuando no fuere posible atender la consulta dentro de dicho
                    término, se informará al interesado antes del vencimiento de
                    los diez (20) días, expresando los motivos de la demora y
                    señalando la fecha en que se atenderá su consulta, la cual
                    en ningún caso podrá superar los cinco (8) días hábiles
                    siguientes al vencimiento del primer plazo.<br /><br />

                    Reclamos, peticiones, solicitudes de supresión o
                    actualización de información: El Titular o sus
                    causahabientes que consideren que la información contenida
                    en una base de datos debe ser objeto de corrección,
                    actualización o supresión, o cuando adviertan el presunto
                    incumplimiento de cualquiera de los deberes contenidos en la
                    ley, podrán presentar un reclamo ante
                    <b>S.O.S CONTINGENCIAS S.A.S</b>., el cualserá tramitado
                    bajo las siguientes reglas:
                  </p>
                  <ul>
                    <li>
                      El reclamo del Titular se formulará mediante solicitud
                      dirigida a <b>S.O.S CONTINGENCIAS S.A.S</b>., al correo
                      electrónico:
                      <a>servicioalcliente@soscontingencias.com.co</a> con la
                      identificación del titular, la descripción de los hechos
                      que dan lugar al reclamo, la dirección, y acompañando los
                      documentos que se quiera hacervaler.
                    </li>

                    <li>
                      El término máximo para atender el reclamo será de quince
                      (15) días hábiles contados a partir del día siguiente a la
                      fecha de su recibo. Cuando no fuere posible atender el
                      reclamo dentro de dicho término, se informará al
                      interesado los motivos de la demora y la fecha en que se
                      atenderá su reclamo, la cual en ningún caso podrá superar
                      los ocho (8) días hábiles siguientes al vencimiento del
                      primer término.
                    </li>
                  </ul>
                  <h4>SEGURIDAD DE LA INFORMACIÓN Y MEDIDAS DE SEGURIDAD</h4>
                  <p>
                    Dando cumplimiento al principio de seguridad establecido en
                    la normatividad vigente, <b>S.O.S CONTINGENCIAS S.A.S</b>,
                    adoptará las medidas técnicas, humanas y administrativas que
                    sean necesarias para otorgar seguridad a los registros
                    evitando su adulteración, pérdida, consulta, uso o acceso no
                    autorizado o fraudulento.
                  </p>
                  <h4>
                    RESPONSABLE Y ENCARGADO DEL TRATAMIENTO DE DATOS PERSONALES
                  </h4>
                  <p>
                    <b>S.O.S CONTINGENCIAS S.A.S</b>, será la responsable del
                    tratamiento de los datos personales. El Comité de Seguridad
                    de la Información será el encargado del tratamiento de los
                    datos personales, por cuenta de
                    <b>S.O.S CONTINGENCIAS S.A.S</b>
                  </p>
                  <h4>VIGENCIA</h4>
                  <p>
                    La presente política rige a partir de la fecha de su
                    expedición y aprobación y deja sin efectos los reglamentos o
                    manuales especiales que se hubiesen podido adoptar por
                    instancias laborales y/o administrativas
                    <b>S.O.S CONTINGENCIAS S.A.S</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoliticaPrivacidad",

  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },

  mounted() {
    this.toggleBodyClass("addClass", "sidebar-collapse");
    this.toggleBodyClass("removeClass", "sidebar-mini");
  },

  destroyed() {
    this.toggleBodyClass("removeClass", "sidebar-collapse");
    this.toggleBodyClass("addClass", "sidebar-mini");
  },
};
</script>

<style>
.login-block {
  background: #001871; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: inline-block;
  position: relative;
}

.button-container a {
  bottom: 3em;
  right: 4em;
  color: white;
  text-transform: uppercase;
  padding: 1em 1.8em;
  opacity: 0.3;
  color: #fff !important;
  background: #ff0000;
  position: absolute;
  outline: 2px solid;
  outline-color: rgb(248, 8, 8);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.button-container a:hover {
  box-shadow: inset 0 0 20px rgb(248, 8, 8), 0 0 20px rgb(248, 8, 8);
  outline-color: rgba(49, 138, 172, 0);
  outline-offset: 80px;
  text-shadow: 3px 3px 3px #fff;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 3px solid #09cce6;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  border: 2px solid #09cce6;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
